<template>
  <v-app>
    <AppBar />

    <Drawer />

    <CoreView />
  </v-app>
</template>

<script>
//import "../../styles/main.scss";
export default {
  name: "DashboardIndex",

  components: {
    AppBar: () => import("../../components/molecules/AppBar.vue"),
    Drawer: () => import("../../components/molecules/Drawer.vue"),
    CoreView: () => import("../../components/molecules/CoreView.vue"),
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
